<template>
    <div class="container content">
        <div>
        <b-breadcrumb separator="has-succeeds-separator" size="is-medium">
            <b-breadcrumb-item tag='router-link' to="/admin-dashboard">Admin</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="/admin-users">Käyttäjärekisteri</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' :to="'/admin-users/'+$route.params.userId" active>{{ user.first_name }} {{ user.last_name }}</b-breadcrumb-item>
        </b-breadcrumb>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Käyttäjätiedot</p>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="Etunimi">
                                <b-input v-model="user.first_name"></b-input>
                            </b-field> 
                        </div>
                        <div class="column is-6">
                            <b-field label="Sukunimi">
                                <b-input v-model="user.last_name"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <b-field label="Sähköpostiosoite">
                                <b-input v-model="user.email"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="Puhelinnumero">
                                <b-input v-model="user.mobile"></b-input>
                            </b-field> 
                        </div>
                        <div class="column is-6">
                            <b-field label="Kieli">
                                <b-select v-model="user.lang" placeholder="Kieli">
                                    <option value="fi">SUOMI</option>
                                    <option value="en">ENGLANTI</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                 
                    <div style="text-align: right; margin-top: 20px;">
                        <b-button @click="updateUser" size="is-medium">
                           Tallenna muutokset
                        </b-button>
                    </div>
                </article>            
            </div>
            <div v-if="user.type == 'enduser'" class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Saldo</p>
                    <div style="float: right; margin-top: 10px; padding:10px; font-size: 1rem; border: solid 1px #283B6B;" v-if="account.last_expired">
                            Vanhentunut saldo: {{ account.expired_balance }} &euro;
                            <br />Vanhentunut: {{ account.last_expired | finpvm}}
                    </div>
                    <div style="float: right; margin: 10px; padding:10px; font-size: 1rem; border: solid 1px #283B6B;">
                        Voimassa oleva saldo: {{ account.balance }} &euro;<br />
                        Käytetty saldo: {{ account.used_balance }} &euro;<br />
                    </div>
                    <table>
                        <tr>
                            <th>Saldo</th>
                            <th>Saldo vanhenee</th>
                            <th>Toiminnot</th>
                        </tr>
                        <tr v-for="acc in onlyAccountsWithPositiveBalance" :key="acc.uuid">
                            <td>{{ acc.balance }}</td>
                            <td>{{ acc.expires | finpvm }}</td>
                            <td><!--<button @click="promptUpdateExpirationData(acc)" class="button">Päivämuutos</button> <button @click="promptUpdateBalance(acc)" class="button">Saldomuutos</button> --><button @click="confirmAccountDelete(acc)" class="button">Poista</button></td>
                        </tr>
                    </table>
                    <hr />
                    <b-field label="Lisää saldoa syöttämällä määrä ja vanhentumispäivä.">
                        <b-input type="number" v-model="newAccount.balance"></b-input>
                        <b-datepicker
                            ref="datepicker"
                            :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                            :day-names="['su','ma','ti','ke','to','pe','la']"
                            :date-formatter="_formatDate"
                            :first-day-of-week="1"
                            v-model="newAccount.expires">
                        </b-datepicker>
                        <b-button @click="addBalance">Lisää saldoa</b-button>
                    </b-field>
                </article>
            </div>
        </div>  
        <div class="tile is-ancestor" v-if="user.type == 'enduser'">
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Ostetut koulutukset</p>
                    <div class="columns">
                        <div class="column is-12">
                            <table style="width: 100%;">
                                <tr>
                                    <th>Ostopäivä</th>
                                    <th>Kurssi</th>
                                    <th>Hinta</th>
                                </tr>
                                <tr v-for="purchase in purchases" :key="purchase.uuid">
                                    <td>{{ purchase.created_at | finpvm}}</td>
                                    <td>{{ purchase.description }}</td>
                                    <td>{{ purchase.price }}&euro;</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </article>
            </div>
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Koulutuksen osto käyttäjän puolesta</p>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="Valitse koulutuskumppani">
                                <b-autocomplete
                                    v-model="partnerSearch"
                                    placeholder="Etsi koulutuskumppanin nimellä"
                                    :keep-first="false"
                                    :open-on-focus="true"
                                    :data="filteredPartner"
                                    field="name"
                                    @select="(option) => selectPartner(option)"
                                    :clearable="true"
                                >
                                </b-autocomplete>
                            </b-field>
                            <b-field label="Valitse kurssi" v-if="selectedPartner">
                                <b-select placeholder="Valitse kurssi" v-model="course">
                                    <option
                                        v-for="option in courses"
                                        :value="option.id"
                                        :key="option.id">
                                        {{ option.name }}
                                    </option>
                                    <option value="custom">Muu (syötän tiedot itse)</option>
                                </b-select>
                            </b-field>
                            <b-field v-if="course == 'custom'" label="Kurssi">
                                <b-input v-model="newTransaction.meta"></b-input>
                            </b-field>
                            <b-field v-if="course == 'custom'" label="Hinta">
                                <b-input v-model="newTransaction.amount"></b-input>
                            </b-field>
                            <b-field v-if="course != 'custom' && course != ''" label="Hinta">
                                <b-input v-model="newTransaction.amount"></b-input>
                            </b-field>
                            <button class="button" @click="purchaseCourse" v-if="newTransaction.meta">Osta</button>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
//import eventBus from '@/eventBus';
export default {
    filters: {
        finpvm: function (_date) {
            return moment(_date, "YYYY-MM-DD hh:mm:ss").format(
                "DD.MM.YYYY"
            );
        },
    },
    data() {
        return {
            purchases: [],
            courses: [],
            course: '',
            partnerSearch: '',
            partners: [],
            selectedPartner: false,
            account: {
                accounts: [],
                balance: 0,
                used_balance: 0,
                expired_balance: 0,
                last_expired: false,
                expires: false,
                expire_date: false
            },
            user: {
                id: "",
                type: "",
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
            },
            newAccount: {
                balance: 0,
                expires: this.$moment()._d,

            },
            newTransaction: {
                meta: '',
                amount: 0,
                type: 'purchase',
                course_id: false,
                from_type: 'enduser',
                to_type: 'education_partner',
                from: false,
                to: false,
            }
        }
    },
    computed: {
        onlyAccountsWithPositiveBalance() {
            let accounts = JSON.parse(JSON.stringify(this.account.accounts));
            let positiveAccounts = [];
            accounts.forEach((account) => {
                if(account.balance > 0) {
                    positiveAccounts.push(account);
                }
            })
            return positiveAccounts; 
        },
        filteredPartner() {
            return this.partners.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.partnerSearch.toLowerCase()) >= 0
                )
            })
        }
    },
    watch: {
        selectPartner(newVal) {
            console.log(newVal);
        },
        course: function (course) {
            if(course != '' && course != 'custom') {
                this.getCourse(course);
            }
            console.log("COURSE",course);
        }
    },
    methods: {
        selectPartner(partner) {
            console.log('selectPartner', partner)
            if(partner !== null && typeof partner === 'object') {
                this.selectedPartner = partner;
                this.getCourses(partner.id);
            } 
            else {
                this.selectedPartner = false;
                this.courses = [];
                this.course = '';
                this.newTransaction.meta = "";
                this.newTransaction = {
                    meta: '',
                    amount: 0,
                    type: 'purchase',
                    course_id: false,
                    from_type: 'enduser',
                    to_type: 'education_partner',
                    from: false,
                    to: false,
                }
            }

        },
        promptUpdateExpirationData(acc) {
            console.log(acc);
        },
        promptUpdateBalance(acc) {
            this.$buefy.dialog.prompt({
                message: `Muokkaa saldoa`,
                inputAttrs: {
                    type: 'number',
                    placeholder: 'Uusi saldo',
                    value: acc.balance
                },
                trapFocus: true,
                onConfirm: (value) => this.$buefy.toast.open(`Your age is: ${value}`)
            })
        },
        confirmAccountDelete(acc) {
            this.$buefy.dialog.confirm({
                title: 'Talletuksen poisto',
                message: 'Haluatko varmasti poistaa ' + acc.balance + ' euron talletuksen käyttäjän tililtä?',
                confirmText: 'Poista talletus',
                cancelText: 'Peruuta',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: (() => {
                    axios.delete('/account/'+ acc.uuid).then((response) => {
                        console.log(response);
                        this.getAccount();
                        this.$buefy.toast.open('Talletus poistettu!')
                    }); 
                }) 
            })
        },
        addBalance() {
            axios.post('/account/'+ this.user.uuid, this.newAccount).then((response) => {
                this.newAccount = {
                    balance: 0,
                    expires: this.$moment()._d,
                }
                console.log(response.data);
                this.getAccount();
            });  
        },
        getAccount() {
            axios.get('/account/'+ this.user.uuid).then((response) => {
                console.log(response.data);
                this.account = response.data;
            }); 
        },
        getUser() {
            axios.get('/users/'+ this.$route.params.userId).then((response) => {
                console.log(response.data);
                this.user = response.data;
                this.getAccount();
                this.getPurchases();
            });
        },
        updateUser() {
            axios.put('/users/'+this.user.id,this.user).then((response) => {
                console.log(response.data);
            })
        },
        getEducationPartners() {
            axios.get('/education-partners').then((response) => {
                console.log('educators',response.data)
                this.partners = response.data;
            });
        },
        getCourses(partnerId) {
            console.log('get courses for ' +partnerId);
            this.courses = [];
            axios.get('courses/byeducator/'+partnerId).then((response) => {
                console.log('courses of ' +partnerId, response.data)
                this.courses = response.data;
            })
        },
        getPurchases() {
            this.purchases = [];
            axios.get('purchase/byuser/'+this.user.id).then((response) => {
                this.purchases = response.data;
            })
        },
        getCourse(courseId) {
            console.log('getCourse: ' + courseId)
            axios.get('courses/'+courseId).then((response) => {
                this.newTransaction = {
                    course_id: response.data.id,
                    meta: response.data.name,
                    amount: response.data.price,
                    type: 'purchase',
                    from_type: 'enduser',
                    to_type: 'education_partner',
                    from: this.user.id,
                    to: this.selectedPartner.id
                }
                console.log("course data", response.data);
            }).catch((error) => {
                console.log(error);
            })
        },
        purchaseCourse() {
            this.newTransaction.from = this.user.id;
            this.newTransaction.to = this.selectedPartner.id;
            this.$buefy.dialog.confirm({
                    title: 'Vahvista ostos',
                    message: 'Koulutus: ' +this.newTransaction.meta + '<br/>Hinta: ' +this.newTransaction.amount +'&euro;',
                    cancelText: 'Peruuta',
                    confirmText: 'Vahvista',
                    type: 'is-success',
                    onConfirm: (() => {
                        axios.post('/purchase', this.newTransaction).then(() => {
                            this.$buefy.toast.open('Osto onnistui!')
                            this.selectedPartner = false;
                            this.partnerSearch = "";
                            this.course = '',
                            this.newTransaction = {
                                meta: '',
                                amount: 0,
                                type: 'purchase',
                                course_id: false,
                                from_type: 'enduser',
                                to_type: 'education_partner',
                                from: false,
                                to: false,
                            }
                            this.getUser();
                        });
                    })
                })
            
        }
    },
    mounted() {
        this.getUser();    
        this.getEducationPartners();            
    }
}
</script>